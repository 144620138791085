import React from 'react'
import styles from './Mailing.module.scss';


const Mailing = (props) => (
    <div className="mailing_list">
        <h2 className={styles["title"]}>Sign up for my newsletter</h2>
        <p className={styles["subtitle"]}>Subscribe to get my latest content by email.</p>
        <div id="mc_embed_signup">
        <form action="https://gmail.us3.list-manage.com/subscribe/post?u=f2c36f719dc573d01601d85b7&amp;id=5dce491ccf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
            <input type="email" name="EMAIL" className={styles['email_input']} id="mce-EMAIL" placeholder="email address" required></input>
            <div className={styles["bot"]} aria-hidden="true"><input type="text" name="b_f2c36f719dc573d01601d85b7_5dce491ccf" tabIndex="-1"></input></div>
            <button type="submit" name="subscribe" id="mc-embedded-subscribe" className={styles["subscribe"]}>Subscribe</button>
            </div>
        </form>
        </div>
    </div>




)

export default Mailing